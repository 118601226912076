import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function DepositPaid() {
  const {t} = useTranslation()
  const navigate = useNavigate()
    const [selectedBtn,setSelectedBtn] = useState("hindi")
  return (
<div className='Step1'>

        <p className='pforStep1'>{t('title19')}
</p>
         <div className='btn-container'>
            <button onClick={()=>setSelectedBtn("hindi")} className={selectedBtn==="hindi"?'checked':'dontChecked'}>{t('yes')}</button>
            <button onClick={()=>setSelectedBtn("georgian")} className={selectedBtn==="georgian"?'checked':'dontChecked'}>{t('no')}</button>
         </div>

 <div className='otherContainer'>
    <p className='pforStep1'>{t('subTitle20')}</p>
    <div className='input-form' >
    <input className='inputs' type='number' placeholder={t('placeHolder20')}/>
    </div>
    </div>
    <button style={{marginTop:'120px'}} onClick={()=>navigate('/prelast')} className='Btn' >{t('next')}</button>
</div>
  )
}

export default DepositPaid