import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Progress from "../../components/Progress";
import { useEffect } from "react";
import BackButton from "../../components/BackButton";
import Liabilitie from "../../components/Liabilitie";
import userIcon from "./../../imgs/user-01.png";
import carIcon from "./../../imgs/car-01.png";
import ButtonWithRadio from "../../components/ButtonWithRadio";
import homeIcon from "./../../imgs/home-05.png";
import CardIcon from "./../../imgs/Bank Card information 1.png";
import emnkanIcon from "./../../imgs/image 3.png";
import Cytezen from "./../Steps/Cytezen";
import Modla from "../../components/Modla";
import Input from "../../components/Input";
import Slider from "../../components/Slider";
import Sliders from "../../components/Slider";
import { toast } from "react-toastify";
import i18n from "../../i18";
import InputWithSelect from "../../components/InputWithSelect";
import axios from "axios";
import { convertToNumber } from "../../Utils/helper";

function Step2() {
  const [simah, isSimah] = useState(0);
  const [token, setToken] = useState("");
  const [personalLiabilities, setPersonalLiabilities] = useState([]);
  const [carLiabilities, setCarlLiabilities] = useState([]);
  const [mortgageLiabilities, setMortgagelLiabilities] = useState([]);
  const [cardLiabilities, setCardlLiabilities] = useState([]);
  const [emkan, setEmkan] = useState([]);
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState(6);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("Token");
    const body = localStorage.getItem("step2");
    if (body) {
      // setAmount(
      //   i18n.language == "ar"
      //     ? numericToArabic(+JSON.parse(body)?.obligations[0]?.amount)
      //     : +JSON.parse(body)?.obligations[0]?.amount || ""
      // );
      setAmount(JSON.parse(body)?.obligations[0]?.amount || ""
      );
      isSimah(JSON.parse(body)?.defaultSimah == false ? 1 : 2);
      setDate(JSON.parse(body)?.obligations[0]?.monthsRemaining / 12 || 0.5);
    }
    setToken(token);
  }, []);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const changesFunctions = {
    1: setPersonalLiabilities,
    2: setCarlLiabilities,
    3: setMortgagelLiabilities,
    4: setCardlLiabilities,
    5: setEmkan,
  };
  const valuees = {
    1: personalLiabilities,
    2: carLiabilities,
    3: mortgageLiabilities,
    4: cardLiabilities,
    5: emkan,
  };
  function arabicToNumeric(arabicNumber) {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    let numericValue = 0;
    let numberAr = arabicNumber?.replaceAll(",", "");
    for (let i = 0; i < numberAr?.length; i++) {
      const digit = arabicDigits?.indexOf(numberAr[i]);
      if (digit !== -1) {
        numericValue = numericValue * 10 + digit;
      } else {
        return NaN;
      }
    }

    return numericValue;
  }
  function numericToArabic(number) {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    const numericString = String(number);
    let arabicString = "";
    for (let i = 0; i < numericString.length; i++) {
      const digit = parseInt(numericString[i]);
      arabicString += arabicDigits[digit];
    }

    return arabicString;
  }

  const validateInputs = () => {
    let newErrors = {};

    // Validation for liabilities
    if (amount === "") {
      newErrors.amount = t("liabilitiesRequired");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateWork = (body) => {
    if (!validateInputs()) {
      return;
    }

    localStorage.setItem("step2", JSON.stringify({
      ...body,
      obligations: [
        {
          obligationType: 6,
          amount:amount,
          monthsRemaining: date * 12,
        },
      ],
    }));
    localStorage.setItem("obligationAmount", JSON.stringify(convertToNumber(amount)));
    axios
      .put("/leads/update-lead-obligations", body)
      .then(() => navigate("/homeownership"))
      .catch((e) => toast.error(e?.message));
  };
  const body = {
    obligations: [
      {
        obligationType: 6,
        amount:convertToNumber(amount),
          // i18n.language == "ar" ? "" + arabicToNumeric("" + amount) : amount,
        monthsRemaining: date * 12,
      },
    ],
    defaultSimah: simah == 1 ? false : true,
    token: token,
  };
  return (
    <div className="conatainer">
      <div className="progress-container">
        <Progress done={30} />
        <p className="progress-value">30%</p>
      </div>
      <h1 className="header-text">{t("title3")}</h1>
      <p className="info-text">{t("liabilitiesTitle")}</p>
      <InputWithSelect
        type={"number"}
        name={t("ammount")}
        style={{ paddingTop: "0" }}
        value={amount}
        changeValue={setAmount}
        placeHolder={t("ammount")}
      />
      {errors.amount && (
        <span className="error">{t("liabilitiesRequired")}</span>
      )}
      <p className="info-text">{t("liabilitiesSliderTitle")}</p>
      <Sliders value={date} setValue={setDate} />
      {/* <div className='liabilites-wrapper'>
      <Liabilitie icon={userIcon} name={t('liabilities1')} type={1} value={valuees} setValue={changesFunctions}/>
      <Liabilitie icon={carIcon} name={t('liabilities2')}  type={2} value={valuees} setValue={changesFunctions}/>
      <Liabilitie icon={homeIcon} name={t('liabilities3')} type={3} value={valuees} setValue={changesFunctions}/>
      <Liabilitie icon={CardIcon} name={t('liabilities4')} type={4} value={valuees} setValue={changesFunctions}/>
      <Liabilitie icon={emnkanIcon} name={t('Emkan')} type={5} value={valuees} setValue={changesFunctions}/>
      </div> */}
      {/* <h1 className='header-text'>{t('title4')}</h1> */}
      <div
        style={{ paddingTop: "0px", width: "100%" }}
        className="radio-wraper"
      >
        <p className="info-text">{t("simahText")}</p>
        <div>
          <ButtonWithRadio
            name={t("simahValuw2")}
            value={1}
            pilote={simah}
            setPilote={isSimah}
          />
          <ButtonWithRadio
            name={t("simahValue1")}
            value={2}
            pilote={simah}
            setPilote={isSimah}
          />
        </div>
      </div>
      <div className="btn-container">
        <BackButton url="/workinfo" />
        <button
          className="green-btn"
          style={{ opacity: !simah ? "0.7" : "1" }}
          onClick={() => updateWork(body)}
          disabled={!simah}
        >
          {t("nextBtn")}
        </button>
      </div>
    </div>
  );
}

export default Step2;
