import React, { useEffect, useState } from "react";
import icon from "./../imgs/chevron-down.png";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import NumberInput from "./numberInput";

function InputWithSelect({ title, placeHolder, changeValue, value, info }) {
  const arabicDigits = [
    "٠",
    "١",
    "٢",
    "٣",
    "٤",
    "٥",
    "٦",
    "٧",
    "٨",
    "٩",
    " ",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
  ];
  const westernToArabicMap = {
    0: "٠",
    1: "١",
    2: "٢",
    3: "٣",
    4: "٤",
    5: "٥",
    6: "٦",
    7: "٧",
    8: "٨",
    9: "٩",
  };
  const { t, i18n } = useTranslation();
  function formatArabicNumberWithSpaces(numberString) {
    if (!numberString) return "";

    const formattedString = String(numberString)?.replace(/,/g, "");

    let commaStr = "";
    let counter = 0;
    for (let i = formattedString.length - 1; i >= 0; i--) {
      counter += 1;
      const el = formattedString[i];
      commaStr = el + commaStr;

      if (counter % 3 === 0 && counter !== formattedString.length) {
        commaStr = "," + commaStr;
      }
    }
    return commaStr;
  }
  function convertArabicToWestern(arabicString) {
    if (!arabicString) return "";
    const arabicChars = String(arabicString)?.split("");
    const westernChars = arabicChars.map((char) => {
      return westernToArabicMap[char] || char;
    });

    return westernChars.join("");
  }
  function arabicToNumeric(arabicNumber) {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    let numericValue = 0;
    let numberAr = String(arabicNumber)?.replaceAll(",", "");
    for (let i = 0; i < numberAr?.length; i++) {
      const digit = arabicDigits?.indexOf(numberAr[i]);
      if (digit !== -1) {
        numericValue = numericValue * 10 + digit;
      } else {
        return "";
      }
    }
    return numericValue;
  }

  // useEffect(() => {
  //   const finalValue =
  //     i18n.language == "en"
  //       ? arabicToNumeric(value)
  //       : convertArabicToWestern(value);
  //   changeValue(finalValue);
  // }, [i18n.language]);

  function removeNonNumericCharacters(inputString) {
    // Use a regular expression to match only digits (including Arabic digits)
    let numericString = inputString.match(/[0-9٠-٩]+/g);
    // Join all matched digits into a single string
    return numericString ? numericString.join("") : "";
  }

  return (
    <div className="input-select-container">
      <p>{title}</p>
      <label>
        {/* {i18n.language == "ar" ? (
          <input
            onChange={(e) => {
              let val = e.target.value;
              // val = removeNonNumericCharacters(val);
              // val = formatArabicNumberWithSpaces(val);
              // val = convertArabicToWestern(val);
              changeValue(val);
            }}
            value={
              value
              // i18n.language == "en"
              //   ? value
              //   : formatArabicNumberWithSpaces(value)
            }
            type="number"
            placeholder={placeHolder}
          />
        ) : ( */}
        <NumberInput
        placeholder={placeHolder}
        value={value}
        changeValue={(value) => changeValue(value)}
        />
          {/* <CurrencyInput
            allowNegativeValue={false}
            placeholder={placeHolder}
            value={value}
            onValueChange={(value, name, values) => changeValue(value)}
          /> */}
        <label className="input-select-label">
          <span>{t("moneyType")}</span>
        </label>
      </label>
      {info && i18n.language == "ab" ? <span>{info}</span> : null}
    </div>
  );
}
{
  /* <CurrencyInput
 allowNegativeValue={false}
  placeholder={placeHolder}
  value={value}
  onValueChange={(value, name, values) => changeValue(value)}
/> */
}
export default InputWithSelect;
