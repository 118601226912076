import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

const NextButton = ({ disabled, loading, onClick }) => {
  const { t } = useTranslation();

  return (
    <button
      className="green-btn"
      style={{
        background: disabled || loading ? "#D3D3D3" : "var(--Brand-Green, #52B44A)",
        opacity: disabled ? "0.5" : "1",
        cursor: disabled || loading ? "not-allowed" : "pointer",
      }}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading && (
        <CircularProgress size={24} color="white" style={{ marginRight: 10 }} />
      )}{" "}
      {loading ? "Loading..." : t("nextBtn")}
    </button>
  );
};

export default NextButton;
