import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getData, setMax, setMin } from "../../Utils/storage";
import i18n from "i18next";
import Progress from "../../components/Progress";
import BackButton from "../../components/BackButton";
import ButtonWithRadio from "../../components/ButtonWithRadio";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

function Info() {
  const persistValue = localStorage.getItem("eligible");
  const preservedValue = persistValue && JSON.parse(persistValue);

  const [eligible, setEligible] = useState(parseInt(preservedValue) || 0);
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation();
  const [token, setToken] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("Token");
    setToken(token);
  }, []);

  useEffect(() => {
    if (getData().firstMortgage == null) {
      navigate("/homeownership");
    }
    setData(getData());
  }, []);

  const updateData = (body) => {
    axios
      .put("/leads/update-lead", body)
      .then((e) => {
        // Clear local storage
        ["step1", "step2", "step3", "step4", "eligible"].forEach((item) =>
          localStorage.removeItem(item)
        );
        setMax(e?.data?.maxWithout);
        setMin(e?.data?.max);
        navigate("/calendartype");
      })
      .catch((e) => toast.error(e.message));
  };

  const navigate = useNavigate();

  const onChange = (value) => {
    localStorage.setItem("eligible", JSON.stringify(value));
    localStorage.setItem("isEligible", JSON.stringify(value));
    setEligible(value);
  };

  return (
    <div className="conatainer">
      <div className="progress-container">
        <Progress done={90} />
        <p className="progress-value">90%</p>
      </div>
      <h1 className="header-text">{t("title6")}</h1>
      <div className="green-text-wrapper">
        <span>{t("infoHeader2")}</span>
      </div>
      <div className="info" style={{ width: "100%" }}>
        <div className="radio-wraper">
          <p className="info-text">{t("Eligible")}</p>
          <div>
            <ButtonWithRadio
              name={t("yes")}
              value={1}
              pilote={eligible}
              setPilote={onChange}
            />
            <ButtonWithRadio
              name={t("no")}
              value={2}
              pilote={eligible}
              setPilote={onChange}
            />
            <ButtonWithRadio
              name={t("notSure")}
              value={3}
              pilote={eligible}
              setPilote={onChange}
            />
          </div>
        </div>
      </div>

      <div className="btn-container">
        <BackButton url="/homeownership" />
        <button
          className="green-btn"
          disabled={!eligible}
          style={{ opacity: !eligible ? "0.7" : "1" }}
          onClick={() =>
            updateData({
              redf: eligible == 1 ? true : eligible == 2 ? false : false,
              ...data,
              token,
            })
          }
        >
          {t("preLastBtn")}
        </button>
      </div>
    </div>
  );
}

export default Info;
