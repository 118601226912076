import logo from "./logo.svg";
import "./App.css";
import "./i18";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PublicWrapper from "./layouts/PublicWrapper";
import PrivateWrapper from "./layouts/PrivateWrapper";
import Start from "./pages/FirstPage/Start";
import Phone from "./pages/Steps/Phone";
import Code from "./pages/Steps/Code";
import Location from "./pages/Steps/Location";
import TypeYourProd from "./pages/Steps/TypeYourProd";
import Info from "./pages/Steps/Info";
import CalendarTypes from "./pages/Steps/CalendarTypes";
import InforWork from "./pages/Steps/InforWork";
import Obligation from "./pages/Steps/Obligation";
import Family from "./pages/Steps/Famly";
import ProportyPrice from "./pages/Steps/ProportyPrice";
import DownPayment from "./pages/Steps/Downpayment";
import DepositPaid from "./pages/Steps/DepositPaid";
import PreLast from "./pages/Steps/PreLast";
import LastPage from "./pages/FirstPage/LastPage";
import GuideFirst from "./pages/Steps/GuideFirst";
import GuideSecond from "./pages/Steps/GuideSecond";
import GuideThird from "./pages/Steps/GuidThird";
import { useEffect, useState } from "react";
import FindProperty from "./pages/Steps/FindProperty";
import Confirmation from "./pages/Steps/Confirmation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setupAxiosConfig } from "./Utils/api";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const token = urlParams.get("Token");
setupAxiosConfig(token);
if (token) {
  localStorage.setItem("Token", token);
}

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("ar");
    if (urlParams.get("lang")) {
      i18n.changeLanguage(
        urlParams.get("lang") === "ar" ? "ar" : urlParams.get("lang")
      );
    }
    if (i18n.language === "ar") {
      document.querySelector("html").dir = "rtl";
    }
    i18n.on("languageChanged", (a) => {
      document.querySelector("html").dir = a === "en" ? "ltr" : "rtl";
    });
  }, []);

  return (
    <div
      style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      className="App"
    >
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateWrapper>
                <Start />
              </PrivateWrapper>
            }
          />
          <Route
            path="/workinfo"
            element={
              <PrivateWrapper>
                <Phone />
              </PrivateWrapper>
            }
          />
          <Route
            path="/obligations"
            element={
              <PrivateWrapper>
                <Code />
              </PrivateWrapper>
            }
          />

          <Route
            path="/homeownership"
            element={
              <PrivateWrapper>
                <TypeYourProd />
              </PrivateWrapper>
            }
          />
          <Route
            path="/eligible"
            element={
              <PrivateWrapper>
                <Info />
              </PrivateWrapper>
            }
          />
          <Route
            path="/calendartype"
            element={
              <PrivateWrapper>
                <CalendarTypes />
              </PrivateWrapper>
            }
          />
          <Route
            path="/findproperty"
            element={
              <PrivateWrapper>
                <FindProperty />
              </PrivateWrapper>
            }
          />
          <Route
            path="/confirmation"
            element={
              <PrivateWrapper>
                <Confirmation />
              </PrivateWrapper>
            }
          />
          <Route
            path="/eligibleWrok"
            element={
              <PrivateWrapper>
                <InforWork />
              </PrivateWrapper>
            }
          />
          <Route
            path="/obligation"
            element={
              <PrivateWrapper>
                <Obligation />
              </PrivateWrapper>
            }
          />

          <Route
            path="/famly"
            element={
              <PrivateWrapper>
                <Family />
              </PrivateWrapper>
            }
          />
          <Route
            path="/price"
            element={
              <PrivateWrapper>
                <ProportyPrice />
              </PrivateWrapper>
            }
          />
          <Route
            path="/payment"
            element={
              <PrivateWrapper>
                <DownPayment />
              </PrivateWrapper>
            }
          />
          <Route
            path="/location"
            element={
              <PrivateWrapper>
                <Location />
              </PrivateWrapper>
            }
          />
          <Route
            path="/guidfirst"
            element={
              <PrivateWrapper>
                <GuideFirst />
              </PrivateWrapper>
            }
          />
          <Route
            path="/guidsecond"
            element={
              <PrivateWrapper>
                <GuideSecond />
              </PrivateWrapper>
            }
          />
          <Route
            path="/guidthird"
            element={
              <PrivateWrapper>
                <GuideThird />
              </PrivateWrapper>
            }
          />
          <Route
            path="/paid"
            element={
              <PrivateWrapper>
                <DepositPaid />
              </PrivateWrapper>
            }
          />
          <Route
            path="/prelast"
            element={
              <PrivateWrapper>
                <PreLast />
              </PrivateWrapper>
            }
          />
          <Route
            path="/last"
            element={
              <PrivateWrapper>
                <LastPage />
              </PrivateWrapper>
            }
          />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
