import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {translatioanAb} from './Local/ab/translatioanAb'
import {translatioanEn} from './Local/en/translatioanEn'

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    debug: true,
    resources: {
        ar: { translation: translatioanAb },
        en: { translation: translatioanEn },
    
    },
    lng: localStorage.getItem('i18nextLng') || 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
