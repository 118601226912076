import React from "react";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";

const massages = {
  1: "by regulation months to complete can't be more than 60 months",
  2: "by regulation months to complete can't be more than 60 months",
  3: "by regulation months to complete can't be more than 360 months",
  4: "by regulation months to complete can't be more than 60 months",
  5: "by regulation months to complete can't be more than 60 months",
};
const massagesAB = {
  1: "استنادا إلى القانون، فإن عدد الاشهر لإكمال لا يمكن أن تتخطى ٦٠ شهرا",
  2: "استنادا إلى القانون، فإن عدد الاشهر لإكمال لا يمكن أن تتخطى ٦٠ شهرا",
  3: "استنادا إلى القانون، فإن عدد الاشهر لإكمال لا يمكن أن تتخطى ٣٠٠  شهرا",
  4: "استنادا إلى القانون، فإن عدد الاشهر لإكمال لا يمكن أن تتخطى ٦٠ شهرا",
  5: "استنادا إلى القانون، فإن عدد الاشهر لإكمال لا يمكن أن تتخطى ٦٠ شهرا",
};
function Input({
  name,
  placeHolder,
  changeValue,
  type,
  value,
  style,
  max,
  id,
}) {
  const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩", " "];
  const { t, i18n } = useTranslation();
  function formatArabicNumberWithSpaces(numberString) {
    if (!numberString) return "";

    const reversedString = numberString
      .replaceAll(" ", "")
      .split("")
      .reverse()
      .join("");
    let formattedString = "";
    for (let i = 0; i < reversedString.length; i++) {
      formattedString += reversedString[i];

      if ((i + 1) % 3 === 0 && i !== reversedString.length - 1) {
        formattedString += " ";
      }
    }

    return formattedString.split("").reverse().join("");
  }
  return (
    <div className="input-label" style={style}>
      <p>{name}</p>
      {!type ? (
        <input
          defaultValue={value}
          type={type ? type : "text"}
          inputMode={type && "numeric"}
          placeholder={placeHolder}
          onChange={(e) => changeValue(e.target.value)}
        />
      ) : type && i18n.language === "ar" ? (
        <input
          onChange={(e) => {
            let val = e.target.value;

            if (val?.split("").every((char) => arabicDigits?.includes(char))) {
              val = formatArabicNumberWithSpaces(val);
              changeValue(val);
            }
          }}
          value={
            i18n.language == "en" ? value : formatArabicNumberWithSpaces(value)
          }
          type="text"
          placeholder={placeHolder}
        />
      ) : (
        <CurrencyInput
          allowNegativeValue={false}
          min={1}
          placeholder={placeHolder}
          value={value}
          onValueChange={(value, name, values) => changeValue(value)}
        />
      )}
      {value > (id == "3" ? 360 : 60) && id ? (
        <span style={{ color: "red" }}>
          {i18n.language == "en" ? massages[id] : massagesAB[id]}
        </span>
      ) : null}
    </div>
  );
}

{
  /* <input 
   onChange={(e)=> {
    let val = Number(e.target.value)||''
    if(val > max){
      val = max
    }
    changeValue((""+val))
   }}
   value={value} type='number' inputMode={type&&'numeric'} placeholder={placeHolder}  max={max} 
   />} */
}
export default Input;
