import React, { useEffect, useState } from 'react'
import './Steps.css'
import { json, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {MilitaryArray,MilitaryArrayAb,salaryOptions,salaryOptionsAb} from './../../Utils/options'
import Progress from '../../components/Progress'
import Buttons from '../../components/Buttons'
import BackButton from '../../components/BackButton'
import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonWithRadio from '../../components/ButtonWithRadio'
import InputWithSelect from '../../components/InputWithSelect'
import DataPicker from '../../components/DatePicker'
import { toast } from 'react-toastify'


function  FindProperty() {
const [price,setPrice] = useState('')
const [downPayment,setDownPayment] = useState(0)
const [city,setCity] = useState('')
const [neighborhood,setNeighborhood] = useState('')
const [property,setProperty] = useState(0)
const [propertyUse,setPropertyUse] = useState(0)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
 


  const normilezeBody = (obj) => {
          if(obj.militaryRank&& !(obj.militaryRank <= 10&& obj.militaryRank!==0)){
            delete obj.isPilot
          }
          if(!obj.salaryBank){
            delete obj.salaryBank
          }
      return  obj
  }

  return (
    <div className='conatainer'>
    <div className='header-texts'>
      <h1 className='header-text'>{t('Discover Your Ideal Home')}</h1>
      <p>Complete the details below and we'll guide you to your perfect home match.</p>
      </div>
      <div className='info' style={{width:'100%'}}>
      <InputWithSelect value={price} title={t("What's the price of your dream property?")} placeHolder={t('placeHolder2')} changeValue={setPrice} /> 
      <div className='radio-wraper'><p className='info-text'>{t('Do you have the required down payment of XX,XXXSAR?')}</p><div><ButtonWithRadio name={t('No, I need support')} value={1} pilote={downPayment} setPilote={setDownPayment} /><ButtonWithRadio name={t('yes')} value={2} pilote={downPayment} setPilote={setDownPayment} /></div></div>
      <InputSelect  value = {city} name={t("City you're interested in?")} placeHolder={t('Select city')} options={i18n.language=='en'?salaryOptions:salaryOptionsAb} changeValue={setCity} /> 
      <InputSelect  value = {neighborhood} name={t("Neighborhood you're considering?")} placeHolder={t('Select city')} options={i18n.language=='en'?salaryOptions:salaryOptionsAb} changeValue={setNeighborhood} />
      <div className='selection-container-second'>
      <p className='info-text' style={{ paddingTop: '24px' }}>{t('What type of property suits your needs?')}</p> 
      <div className='radio-wrapper-row'>
      <ButtonWithRadio name={t('Apartment')} value={1} pilote={property} setPilote={setProperty} />
        <ButtonWithRadio name={t('Villa')} value={2} pilote={property} setPilote={setProperty} />
        <ButtonWithRadio name={t('Floor')} value={3} pilote={property} setPilote={setProperty} />
        <ButtonWithRadio name={t('Land')} value={4} pilote={property} setPilote={setProperty} />
      </div>
        </div> 
     <div className='selection-container-second'>
      <p className='info-text' style={{ paddingTop: '24px' }}>{t('Intended use for your new property?')}</p> 
        <ButtonWithRadio name={t('Primary Residence')} value={1} pilote={propertyUse} setPilote={setPropertyUse} />
        <ButtonWithRadio name={t('Secondary/Vacation Home')} value={2} pilote={propertyUse} setPilote={setPropertyUse} />
        <ButtonWithRadio name={t('Investment Property')} value={3} pilote={propertyUse} setPilote={setPropertyUse} />
  
        </div> 
        {/* {jobType !== 5 ? <DataPicker value={new Date(jobStartDate)} title={t('inputTitle4')} changeValue={setJobstartDate}/> : null} */}
        <div></div>
      
      </div>
      <div className='btn-container'>
        <BackButton url='/calendartype' />
        <button className='green-btn' onClick={() =>navigate('/')}>{t('nextBtn')}</button>
      </div>

    </div>
  )
}

export default FindProperty