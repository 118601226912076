import React, { useEffect } from 'react'
import { useState } from 'react'
import Select from 'react-select'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import i18n from 'i18next';
import InputOption from '../../components/Select'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNavigate } from 'react-router-dom'
import axios from 'axios';

function InforWork() {

  const {id} = useParams()

  const { t, i18n } = useTranslation()
  const options = [
    { value: 1, label: "1-3"},
    { value: 2, label: "3-6" },
    { value: 3, label: "6+" },
  ]

  const workSubSector = [
    { value: 1, label: "Government"},
    { value: 2, label: "Miltary" },
    { value: 3, label: "Private (SME)" },
    { value: 4, label: "Private (Enterprise)" },
    { value: 5, label: "Retired" },
]
const workSubSectorAb = [
  { value: 1, label: "الحكومة"},
  { value: 2, label: "عسكري" },
  { value: 3, label: "(المؤسسات الخاصة (الصغيرة والمتوسطة" },
  { value: 4, label: "(مؤسسة) خاصة" },
  { value: 5, label: "متقاعد" },
]
const MilitaryArray = [
  { value: 1, label: "Lieutenant" },
  { value: 2, label: "First Lieutenant" },
  { value: 3, label: "Captain" },
  { value: 4, label: "Major" },
  { value: 5, label: "Lieutenant Colonel" },
  { value: 6, label: "Colonel" },
  { value: 7, label: "Brigadier" },
  { value: 8, label: "General" },
  { value: 9, label: "Marshal" },
  { value: 10, label: "First Marshal" },
  { value: 11, label: "Private" },
  { value: 12, label: "First Private" },
  { value: 13, label: "Corporal" },
  { value: 14, label: "Second Sergeant" },
  { value: 15, label: "Sergeant" },
  { value: 16, label: "First Sergeant" },
  { value: 17, label: "Staff Sergeant" }
];
const MilitaryArrayAb = [
  { value: 1, label: "ملازم " },
  { value: 2, label: "ملازم أول" },
  { value: 3, label: "نقيب" },
  { value: 4, label: "رائد في الجيش" },
  { value: 5, label: "مقدم " },
  { value: 6, label: "عقيد في الجيش" },
  { value: 7, label: "قائد جنرال" },
  { value: 8, label: "جنرال" },
  { value: 9, label: "مشير " },
  { value: 10, label: "المشير الاول" },
  { value: 11, label: "جندي خاص" },
  { value: 12, label: "جندي خاص صف اول" },
  { value: 13, label: "عريف " },
  { value: 14, label: "رقيب ثاني" },
  { value: 15, label: "رقيب رتبة" },
  { value: 16, label: "رقيب أول" },
  { value: 17, label: "رقيب أول" }
];
const theme = createMuiTheme({
  direction: 'rtl', 
});

  const navigate = useNavigate()
  useEffect(()=>{
    if(!id){
      // navigate('/eligible')
    }
  })
  const [workSector,setWorkSector] = useState({})

  const [militaryRank,setMilitaryRank] = useState({})
  const [isPilot,setIsPilot] = useState(false)
  const [netSalary,setNetSalary] = useState(NaN)
  const [basicSalary,setBasicSalary] = useState(NaN)
  const [housingAllowance,setHousingAllowance] = useState('')
  const [employerName,setEmployerName] = useState('')
  const [otherAllowance,setOtherAllOwance] = useState(NaN)
  const [jobStartDate,setJobStartDate] = useState('')
  const [currentJobStartDate,setCurrentJobStartDate] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedBtn,setSelectedBtn] = useState('No')
  
    const reqBody = {
      1:{
        netSalary: netSalary,
        workSector: workSector?.value,
        employerName:employerName,
        currentJobStartDate: currentJobStartDate?.value,
        basicSalary: basicSalary,
        salay:'',
        jobStartDate: jobStartDate,
        token:id
    },
    2:{
      workSector: workSector?.value,
      militaryRank: militaryRank?.value,
      employerName:employerName,
      isPilot: isPilot,
      netSalary: netSalary,
      basicSalary: basicSalary,
      jobStartDate: jobStartDate,
      currentJobStartDate: currentJobStartDate?.value,
      token:id
    },
    3:{
      netSalary: netSalary,
      workSector: workSector?.value,
      employerName:employerName,
      currentJobStartDate: currentJobStartDate?.value,
      basicSalary: basicSalary,
      salay:'',
      jobStartDate: jobStartDate,
      token:id
    },
    4:{
      netSalary: netSalary,
      workSector: workSector?.value,
      employerName:employerName,
      currentJobStartDate: currentJobStartDate?.value,
      basicSalary: basicSalary,
      salay:'',
      jobStartDate: jobStartDate,
      token:id
    },
    5:{
      workSector: workSector?.value,
      employerName:employerName,
      netSalary: netSalary,
      token:id
    }
    }
    const handelSubmit = () =>{
      axios.put('leads/update-lead-work',reqBody[workSector.value]).then(()=>navigate(`/obligation/${id}`)).catch(e=>console.error(e))
    }
    
  return (
    <div  className='Step1' style={{justifyContent:"space-between"}}>
    <p className='pforStep1'>{t('title2')}</p>
    <div className='inputContainer' >
        <p className='titleforinputs'>{t('subTitle2')}</p>
        <div className='main'>
     <div className='childDiv'>
     <div className='input-form'> 
    <Select
          styles={{height:'60px'}}
          //  isMulti
          placeholder={t('placeHolder1')}
           closeMenuOnSelect={true}
           hideSelectedOptions={false}
           onChange={(options) => {
          
            
            setWorkSector(options);
             
           }}
           options={i18n.language=='en'?workSubSector:workSubSectorAb}
          //  components={{
          //    Option: InputOption
          //  }}
        />
        </div>
      { workSector?.value ==2?
      <>
        <div className='input-form'>
        <p className='pfortitle'>{t('quetion1')}</p>
         <div className='workinfobtns'>
            <button onClick={()=>setIsPilot(false)} className={!isPilot?'checked':'dontChecked'}>{t('no')}</button>
            <button onClick={()=>setIsPilot(true)} className={isPilot?'checked':'dontChecked'}>{t('yes')}</button>
         </div>
         </div>
         <div className='input-form'>
        <input className='inputs' type='number'onChange={(e)=>setNetSalary(e.target.value)} placeholder={t('placeHolder7')}/>
        </div>
         <div className='input-form'>
         <Select
          styles={{height:'60px'}}
          placeholder={t('placeHolder9')}
          //  isMulti
           closeMenuOnSelect={true}
           hideSelectedOptions={false}
           onChange={(options) => {
              setMilitaryRank(options);
             
           }}
           options={i18n.language=='en'?MilitaryArray:MilitaryArrayAb}
          //  components={{
          //    Option: InputOption
          //  }}
        />
        </div>
        </>:<div className='input-form'>
        <input className='inputs' type='number'onChange={(e)=>setNetSalary(e.target.value)} placeholder={t('placeHolder7')}/>
        </div>}
       
        <div className='input-form'>
        <input className='inputs' type='text' onChange={(e)=>setEmployerName(e.target.value)} placeholder={t('placeHolder6')}/>
        </div>
    
        <div className='input-form'>
        {workSector.value!==5?  <Select
          styles={{height:'60px'}}
           placeholder={t('placeHolder3')}
          //  isMulti
           closeMenuOnSelect={true}
           hideSelectedOptions={false}
           options={options}
           onChange={(options) => {
            setCurrentJobStartDate(options)
           }}
          //  options={allOptions}
          //  components={{
          //    Option: InputOption
          //  }}
        />:null}
        </div>
        </div>
        {workSector.value!==5?<div className='childDiv'>
        <div className='input-form'>
        <Select
          styles={{height:'60px'}}
           placeholder={t('placeHolder4')}
          //  isMulti
           closeMenuOnSelect={true}
           hideSelectedOptions={false}
           onChange={(options) => {
             if (Array.isArray(options)) {
               setSelectedOptions(options.map((opt) => opt.value));
             }
           }}
          //  options={allOptions}
          //  components={{
          //    Option: InputOption
          //  }}
        />
        </div>
        {workSubSector.value==2?<div className='input-form'>
        <input className='inputs' type='number' placeholder={t('placeHolder2')}/>
        </div>:null}
        <div className='input-form'>
        <input className='inputs' type='number' placeholder={t('placeHolder5')} onChange={(e)=>setBasicSalary(e.target.value)}/>
        </div>
        <LocalizationProvider  dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
        <DatePicker
          className='arabic'
          onChange={(M)=>setJobStartDate(M?.$d)}
          sx={{width:'100%', borderRadius:"8px"}}
          
         label={t('placeHolder8')}
       />
        </DemoContainer>
        </LocalizationProvider>
          </div>:null}
 
        </div>
    </div>
    {/* <input className='inputs' type='tel' placeholder='Phone Number'/> */}
    <button style={{position:"relative"}} className='Btn'onClick={()=>navigate('/obligation')} type='submit'>{t('next')}</button>
</div>
  )
}

export default InforWork