import React from 'react'
import "./Start.css"
import { useTranslation } from 'react-i18next'
 import { setToken } from '../../Utils/storage'
function LastPage() {
 const {t} = useTranslation()

  return (
    <div style={{justifyContent:"center"}} className='Step1'>
        <h1 className='title'>{t('congurilation')}</h1>
        <p className='info-p'>{t('info1')}</p>
        <p className='info-p'>{t('info2')}</p>
        <p className='info-p'>{t('info3')}</p>
        <button className='Btn' style={{marginTop:'5vh'}}>{t('start')}</button>
    </div>
  )
}

export default LastPage