import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Family() {
  const {t} = useTranslation()
  const navigate = useNavigate()
    const [selectedBtn,setSelectedBtn] = useState("yes")
  return (
<div className='info'>
<p className='pforStep1'>{t('title10')}</p>
<p className='pforStep1'>{t('subtitle11')}</p>
<div  >
        <p className='pforStep1'>{t('info12')}</p>
         <div className='btnsContainer'>
            <button onClick={()=>setSelectedBtn("yes")} className={selectedBtn==="yes"?'checked':'dontChecked'}>{t('yes')}</button>
            <button onClick={()=>setSelectedBtn("no")} className={selectedBtn==="no"?'checked':'dontChecked'}>{t('no')}</button>
            <button onClick={()=>setSelectedBtn("NotSure")} className={selectedBtn==="NotSure"?'checked':'dontChecked'}>{t('notSure')}</button>
         </div>
    </div>
 <div className='otherContainer'>
    <p className='pforStep1'>{t('info11')} </p>
    <div className='input-form' >
    <input className='inputs' type='number' placeholder={t('placeHolder11')}/>
    </div>
    </div>
    <button style={{position:'relative'}} onClick={()=>navigate('/price')} className='Btn' >{t('next')}</button>
</div>
  )
}

export default Family