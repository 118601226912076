import React, { useEffect, useState } from "react";
import "./Steps.css";
import { json, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackButton from "../../components/BackButton";
import ButtonWithRadio from "../../components/ButtonWithRadio";

const handleDragStart = (e) => e.preventDefault();
function Confirmation() {
  const [required, setRequired] = useState(0);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [index, setIndex] = useState(0);
  const [temp, setTemp] = useState(windowSize[0] > 672 ? 5 : 3);
  const [imgCount, setImgCount] = useState(temp);
  const [startIndex, setStartIndex] = useState(0);
  const [slicedArrays, setSlicedArrays] = useState([]);
  const [images, setImages] = useState([
    "https://s3-alpha-sig.figma.com/img/fc51/e3d9/e3daa4bcb3a926ead49818f0e6c4b400?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=V61LXFatjPAxt499P9iZEy7~Li8~CtwHvJQtZNwxU-pq-LBhvW5wMBv9IH6nznghqMH3j5DOBtx7cjfaPkG-DD-oW1LcotyG9Ll4UPT~Jgo0ciroGCknS83-zQ-DWK-BozJFxwdaO1zNYwK0KMMokvCmdfoipbBBHu8bucPG~unD8f1sqgsNvgDvOGrUJKmTKvFn38grHhdfKpj99zvq0NlOiijKFfVOkiacVgkPDOi4MaR3YtEfBKgXhHFbnBVRCKgdhCFlbNNC4gSXB2bsMoJ-buFU7ctHIZWq-m4O9qnwcW51ZLpp1oISII7XLnYok4-QueiG46XxTNjza9BdGg__",
    "https://s3-alpha-sig.figma.com/img/ce89/1166/f72a364b71dbe80b23cfcaac43b3cb85?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bHq3DXYw3O0zkgARxNxlE5y5VvxwGEjGv4ERbA3zXrBBPaIxv8~i9Tw506q6z8saFLGgDGuXhcYh09QCZR8YidiLIokl5PESYS7K5Wo~ju4iA1SO3wRKkFdrbdw81KnhgjTaMZTTHrx6j-XqSh4w79HcWtYWd2LNgjM604BXuat4d1aK6z2qk8rF-d81gE8l6QL0lORx~UetPAK0SXOq01Voj21I1j-9xhtGXvL7UOhmhKTMptALa-mG27OKDTcprVmWdpn4dgJyzgJtgSvogtqcgT7lwS0~WaFTP1O3YoKjw9mKoBJjv94dTiGVWOM8VVZnnbaGyAOvlpTQr4Lgaw__",
    "https://s3-alpha-sig.figma.com/img/fc51/e3d9/e3daa4bcb3a926ead49818f0e6c4b400?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=V61LXFatjPAxt499P9iZEy7~Li8~CtwHvJQtZNwxU-pq-LBhvW5wMBv9IH6nznghqMH3j5DOBtx7cjfaPkG-DD-oW1LcotyG9Ll4UPT~Jgo0ciroGCknS83-zQ-DWK-BozJFxwdaO1zNYwK0KMMokvCmdfoipbBBHu8bucPG~unD8f1sqgsNvgDvOGrUJKmTKvFn38grHhdfKpj99zvq0NlOiijKFfVOkiacVgkPDOi4MaR3YtEfBKgXhHFbnBVRCKgdhCFlbNNC4gSXB2bsMoJ-buFU7ctHIZWq-m4O9qnwcW51ZLpp1oISII7XLnYok4-QueiG46XxTNjza9BdGg__",
    "https://s3-alpha-sig.figma.com/img/ce89/1166/f72a364b71dbe80b23cfcaac43b3cb85?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bHq3DXYw3O0zkgARxNxlE5y5VvxwGEjGv4ERbA3zXrBBPaIxv8~i9Tw506q6z8saFLGgDGuXhcYh09QCZR8YidiLIokl5PESYS7K5Wo~ju4iA1SO3wRKkFdrbdw81KnhgjTaMZTTHrx6j-XqSh4w79HcWtYWd2LNgjM604BXuat4d1aK6z2qk8rF-d81gE8l6QL0lORx~UetPAK0SXOq01Voj21I1j-9xhtGXvL7UOhmhKTMptALa-mG27OKDTcprVmWdpn4dgJyzgJtgSvogtqcgT7lwS0~WaFTP1O3YoKjw9mKoBJjv94dTiGVWOM8VVZnnbaGyAOvlpTQr4Lgaw__",
    "https://s3-alpha-sig.figma.com/img/34a1/5e58/d05ac93c00de8fe8c67c1daa88d7eebb?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=O5NTzyHjMktFufbDQlJqscIs4T5ohKAlH736I-0VAk8aULbeF5baLLgnBeMEZdk2NS8j26OsI7frjHg1ZqKn2MumWVm8xN04oCevePOZWU8FAfdmOi1sz79l8QfsbUSLTY6NWIwo4y~4lPsxYrLLJ04l29BQOtekY20apmpHvT5ZJkZL-4NBlnD5tmtO6YoBKdAjKXSsx3y8KaI901rmZR5kFq8tuvIPIO9NiHS8pT~qtL1amdUR7spa1F8spw-14y718GqMx9~QsOUdjK6TwWbkP6J6n7ZpDrFVbVzWoP9uIUsPs2DOU93YCySD-eVq3xDfsNCn21hoUKOmECis3Q__",
    "https://s3-alpha-sig.figma.com/img/fc51/e3d9/e3daa4bcb3a926ead49818f0e6c4b400?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=V61LXFatjPAxt499P9iZEy7~Li8~CtwHvJQtZNwxU-pq-LBhvW5wMBv9IH6nznghqMH3j5DOBtx7cjfaPkG-DD-oW1LcotyG9Ll4UPT~Jgo0ciroGCknS83-zQ-DWK-BozJFxwdaO1zNYwK0KMMokvCmdfoipbBBHu8bucPG~unD8f1sqgsNvgDvOGrUJKmTKvFn38grHhdfKpj99zvq0NlOiijKFfVOkiacVgkPDOi4MaR3YtEfBKgXhHFbnBVRCKgdhCFlbNNC4gSXB2bsMoJ-buFU7ctHIZWq-m4O9qnwcW51ZLpp1oISII7XLnYok4-QueiG46XxTNjza9BdGg__",
    "https://s3-alpha-sig.figma.com/img/ce89/1166/f72a364b71dbe80b23cfcaac43b3cb85?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bHq3DXYw3O0zkgARxNxlE5y5VvxwGEjGv4ERbA3zXrBBPaIxv8~i9Tw506q6z8saFLGgDGuXhcYh09QCZR8YidiLIokl5PESYS7K5Wo~ju4iA1SO3wRKkFdrbdw81KnhgjTaMZTTHrx6j-XqSh4w79HcWtYWd2LNgjM604BXuat4d1aK6z2qk8rF-d81gE8l6QL0lORx~UetPAK0SXOq01Voj21I1j-9xhtGXvL7UOhmhKTMptALa-mG27OKDTcprVmWdpn4dgJyzgJtgSvogtqcgT7lwS0~WaFTP1O3YoKjw9mKoBJjv94dTiGVWOM8VVZnnbaGyAOvlpTQr4Lgaw__",
    "https://s3-alpha-sig.figma.com/img/34a1/5e58/d05ac93c00de8fe8c67c1daa88d7eebb?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=O5NTzyHjMktFufbDQlJqscIs4T5ohKAlH736I-0VAk8aULbeF5baLLgnBeMEZdk2NS8j26OsI7frjHg1ZqKn2MumWVm8xN04oCevePOZWU8FAfdmOi1sz79l8QfsbUSLTY6NWIwo4y~4lPsxYrLLJ04l29BQOtekY20apmpHvT5ZJkZL-4NBlnD5tmtO6YoBKdAjKXSsx3y8KaI901rmZR5kFq8tuvIPIO9NiHS8pT~qtL1amdUR7spa1F8spw-14y718GqMx9~QsOUdjK6TwWbkP6J6n7ZpDrFVbVzWoP9uIUsPs2DOU93YCySD-eVq3xDfsNCn21hoUKOmECis3Q__",
    "https://s3-alpha-sig.figma.com/img/34a1/5e58/d05ac93c00de8fe8c67c1daa88d7eebb?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=O5NTzyHjMktFufbDQlJqscIs4T5ohKAlH736I-0VAk8aULbeF5baLLgnBeMEZdk2NS8j26OsI7frjHg1ZqKn2MumWVm8xN04oCevePOZWU8FAfdmOi1sz79l8QfsbUSLTY6NWIwo4y~4lPsxYrLLJ04l29BQOtekY20apmpHvT5ZJkZL-4NBlnD5tmtO6YoBKdAjKXSsx3y8KaI901rmZR5kFq8tuvIPIO9NiHS8pT~qtL1amdUR7spa1F8spw-14y718GqMx9~QsOUdjK6TwWbkP6J6n7ZpDrFVbVzWoP9uIUsPs2DOU93YCySD-eVq3xDfsNCn21hoUKOmECis3Q__",
    "https://s3-alpha-sig.figma.com/img/fc51/e3d9/e3daa4bcb3a926ead49818f0e6c4b400?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=V61LXFatjPAxt499P9iZEy7~Li8~CtwHvJQtZNwxU-pq-LBhvW5wMBv9IH6nznghqMH3j5DOBtx7cjfaPkG-DD-oW1LcotyG9Ll4UPT~Jgo0ciroGCknS83-zQ-DWK-BozJFxwdaO1zNYwK0KMMokvCmdfoipbBBHu8bucPG~unD8f1sqgsNvgDvOGrUJKmTKvFn38grHhdfKpj99zvq0NlOiijKFfVOkiacVgkPDOi4MaR3YtEfBKgXhHFbnBVRCKgdhCFlbNNC4gSXB2bsMoJ-buFU7ctHIZWq-m4O9qnwcW51ZLpp1oISII7XLnYok4-QueiG46XxTNjza9BdGg__",
    "https://s3-alpha-sig.figma.com/img/ce89/1166/f72a364b71dbe80b23cfcaac43b3cb85?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bHq3DXYw3O0zkgARxNxlE5y5VvxwGEjGv4ERbA3zXrBBPaIxv8~i9Tw506q6z8saFLGgDGuXhcYh09QCZR8YidiLIokl5PESYS7K5Wo~ju4iA1SO3wRKkFdrbdw81KnhgjTaMZTTHrx6j-XqSh4w79HcWtYWd2LNgjM604BXuat4d1aK6z2qk8rF-d81gE8l6QL0lORx~UetPAK0SXOq01Voj21I1j-9xhtGXvL7UOhmhKTMptALa-mG27OKDTcprVmWdpn4dgJyzgJtgSvogtqcgT7lwS0~WaFTP1O3YoKjw9mKoBJjv94dTiGVWOM8VVZnnbaGyAOvlpTQr4Lgaw__",
    "https://s3-alpha-sig.figma.com/img/fc51/e3d9/e3daa4bcb3a926ead49818f0e6c4b400?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=V61LXFatjPAxt499P9iZEy7~Li8~CtwHvJQtZNwxU-pq-LBhvW5wMBv9IH6nznghqMH3j5DOBtx7cjfaPkG-DD-oW1LcotyG9Ll4UPT~Jgo0ciroGCknS83-zQ-DWK-BozJFxwdaO1zNYwK0KMMokvCmdfoipbBBHu8bucPG~unD8f1sqgsNvgDvOGrUJKmTKvFn38grHhdfKpj99zvq0NlOiijKFfVOkiacVgkPDOi4MaR3YtEfBKgXhHFbnBVRCKgdhCFlbNNC4gSXB2bsMoJ-buFU7ctHIZWq-m4O9qnwcW51ZLpp1oISII7XLnYok4-QueiG46XxTNjza9BdGg__",
    "https://s3-alpha-sig.figma.com/img/ce89/1166/f72a364b71dbe80b23cfcaac43b3cb85?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bHq3DXYw3O0zkgARxNxlE5y5VvxwGEjGv4ERbA3zXrBBPaIxv8~i9Tw506q6z8saFLGgDGuXhcYh09QCZR8YidiLIokl5PESYS7K5Wo~ju4iA1SO3wRKkFdrbdw81KnhgjTaMZTTHrx6j-XqSh4w79HcWtYWd2LNgjM604BXuat4d1aK6z2qk8rF-d81gE8l6QL0lORx~UetPAK0SXOq01Voj21I1j-9xhtGXvL7UOhmhKTMptALa-mG27OKDTcprVmWdpn4dgJyzgJtgSvogtqcgT7lwS0~WaFTP1O3YoKjw9mKoBJjv94dTiGVWOM8VVZnnbaGyAOvlpTQr4Lgaw__",
    "https://s3-alpha-sig.figma.com/img/34a1/5e58/d05ac93c00de8fe8c67c1daa88d7eebb?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=O5NTzyHjMktFufbDQlJqscIs4T5ohKAlH736I-0VAk8aULbeF5baLLgnBeMEZdk2NS8j26OsI7frjHg1ZqKn2MumWVm8xN04oCevePOZWU8FAfdmOi1sz79l8QfsbUSLTY6NWIwo4y~4lPsxYrLLJ04l29BQOtekY20apmpHvT5ZJkZL-4NBlnD5tmtO6YoBKdAjKXSsx3y8KaI901rmZR5kFq8tuvIPIO9NiHS8pT~qtL1amdUR7spa1F8spw-14y718GqMx9~QsOUdjK6TwWbkP6J6n7ZpDrFVbVzWoP9uIUsPs2DOU93YCySD-eVq3xDfsNCn21hoUKOmECis3Q__",
    "https://s3-alpha-sig.figma.com/img/fc51/e3d9/e3daa4bcb3a926ead49818f0e6c4b400?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=V61LXFatjPAxt499P9iZEy7~Li8~CtwHvJQtZNwxU-pq-LBhvW5wMBv9IH6nznghqMH3j5DOBtx7cjfaPkG-DD-oW1LcotyG9Ll4UPT~Jgo0ciroGCknS83-zQ-DWK-BozJFxwdaO1zNYwK0KMMokvCmdfoipbBBHu8bucPG~unD8f1sqgsNvgDvOGrUJKmTKvFn38grHhdfKpj99zvq0NlOiijKFfVOkiacVgkPDOi4MaR3YtEfBKgXhHFbnBVRCKgdhCFlbNNC4gSXB2bsMoJ-buFU7ctHIZWq-m4O9qnwcW51ZLpp1oISII7XLnYok4-QueiG46XxTNjza9BdGg__",
    "https://s3-alpha-sig.figma.com/img/ce89/1166/f72a364b71dbe80b23cfcaac43b3cb85?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bHq3DXYw3O0zkgARxNxlE5y5VvxwGEjGv4ERbA3zXrBBPaIxv8~i9Tw506q6z8saFLGgDGuXhcYh09QCZR8YidiLIokl5PESYS7K5Wo~ju4iA1SO3wRKkFdrbdw81KnhgjTaMZTTHrx6j-XqSh4w79HcWtYWd2LNgjM604BXuat4d1aK6z2qk8rF-d81gE8l6QL0lORx~UetPAK0SXOq01Voj21I1j-9xhtGXvL7UOhmhKTMptALa-mG27OKDTcprVmWdpn4dgJyzgJtgSvogtqcgT7lwS0~WaFTP1O3YoKjw9mKoBJjv94dTiGVWOM8VVZnnbaGyAOvlpTQr4Lgaw__",
    "https://s3-alpha-sig.figma.com/img/34a1/5e58/d05ac93c00de8fe8c67c1daa88d7eebb?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=O5NTzyHjMktFufbDQlJqscIs4T5ohKAlH736I-0VAk8aULbeF5baLLgnBeMEZdk2NS8j26OsI7frjHg1ZqKn2MumWVm8xN04oCevePOZWU8FAfdmOi1sz79l8QfsbUSLTY6NWIwo4y~4lPsxYrLLJ04l29BQOtekY20apmpHvT5ZJkZL-4NBlnD5tmtO6YoBKdAjKXSsx3y8KaI901rmZR5kFq8tuvIPIO9NiHS8pT~qtL1amdUR7spa1F8spw-14y718GqMx9~QsOUdjK6TwWbkP6J6n7ZpDrFVbVzWoP9uIUsPs2DOU93YCySD-eVq3xDfsNCn21hoUKOmECis3Q__",
    "https://s3-alpha-sig.figma.com/img/34a1/5e58/d05ac93c00de8fe8c67c1daa88d7eebb?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=O5NTzyHjMktFufbDQlJqscIs4T5ohKAlH736I-0VAk8aULbeF5baLLgnBeMEZdk2NS8j26OsI7frjHg1ZqKn2MumWVm8xN04oCevePOZWU8FAfdmOi1sz79l8QfsbUSLTY6NWIwo4y~4lPsxYrLLJ04l29BQOtekY20apmpHvT5ZJkZL-4NBlnD5tmtO6YoBKdAjKXSsx3y8KaI901rmZR5kFq8tuvIPIO9NiHS8pT~qtL1amdUR7spa1F8spw-14y718GqMx9~QsOUdjK6TwWbkP6J6n7ZpDrFVbVzWoP9uIUsPs2DOU93YCySD-eVq3xDfsNCn21hoUKOmECis3Q__",
  ]);
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setTemp(windowSize[0] > 672 ? 5 : windowSize[0] < 417 ? 2 : 3);
    setImgCount(temp);
  }, [windowSize[0]]);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
  };
  const responsive = {
    0: {
      items: 3,
    },
    512: {
      items: 5,
    },
    1024: {
      items: 7,
    },
  };

  const normilezeBody = (obj) => {
    if (
      obj.militaryRank &&
      !(obj.militaryRank <= 10 && obj.militaryRank !== 0)
    ) {
      delete obj.isPilot;
    }
    if (!obj.salaryBank) {
      delete obj.salaryBank;
    }
    return obj;
  };
  const changesPhoto = () => {
    if (imgCount < images.length - 1) {
      setIndex(index + temp);
      setImgCount(imgCount + temp);
    } else {
      setIndex(0);
      setImgCount(temp);
    }
  };
  return (
    <div className="conatainer">
      <div className="header-texts">
        <h1 className="header-text">{t("Discover Your Ideal Home")}</h1>
        <p>
          Complete the details below and we'll guide you to your perfect home
          match.
        </p>
      </div>
      <div className="gray-label">
        <p>
          Apartment for sale in Tawfiq Hussein Street, Al-Rabwah District,
          Jeddah
        </p>
        <div className="img-slider">
          {images.slice(index, imgCount).map((i) => (
            <div
              style={{
                height: "104px",
                minWidth: "104px",
                borderRadius: "10px",
                backgroundImage: `url(${i})`,
                backgroundSize: "cover",
                cursor: "pointer",
              }}
            ></div>
          ))}
          <div className="button-wrapper">
            <svg
              style={{ cursor: "pointer" }}
              onClick={() => changesPhoto()}
              width="64"
              height="68"
              viewBox="0 0 64 68"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_ddd_1718_5433)">
                <circle
                  cx="32"
                  cy="16"
                  r="15.5"
                  fill="white"
                  stroke="#E6E6E6"
                />
                <path
                  d="M29.5 11L34.5 16L29.5 21"
                  stroke="#696969"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs></defs>
            </svg>
          </div>
        </div>
        <div className="apartament-info-container">
          <div className="basic-info">
            <div>
              <p className="header-basic">Basic Information</p> <span></span>
            </div>
            <div>
              <p>Address</p>{" "}
              <span>Al-Baghdadi Street, Al-Bawadi District, Jeddah</span>{" "}
            </div>
            <div>
              <p>Price</p> <span>1,900,000 SAR</span>{" "}
            </div>
            <div>
              <p>Buliding Age</p> <span>Villa</span>{" "}
            </div>
            <div>
              <p>Facing</p> <span>East</span>{" "}
            </div>
            <div>
              <p>Street Width</p> <span>10 m</span>{" "}
            </div>
            <div>
              <p>Area</p> <span>242 m²</span>
            </div>
            <div>
              <p>Aqar Ad Number</p> <span>5601506</span>{" "}
            </div>
          </div>
          <div className="pair-container">
            <div className="basic-info">
              <div>
                <p className="header-basic">Layout</p> <span></span>
              </div>
              <div>
                <p>Halls</p> <span>3</span>{" "}
              </div>
              <div>
                <p>Living Rooms</p> <span>3</span>{" "}
              </div>
              <div>
                <p>Bathrooms</p> <span>5</span>{" "}
              </div>
              <div>
                <p>Stairs</p> <span>Hallway stairs</span>{" "}
              </div>
            </div>
            <div className="basic-info">
              <div>
                <p className="header-basic">Utilities</p> <span></span>
              </div>
              <div>
                <p>Availability of Water</p>{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M4.99967 8.50016L6.99967 10.5002L10.9997 6.50016M14.6663 8.50016C14.6663 12.1821 11.6816 15.1668 7.99967 15.1668C4.31778 15.1668 1.33301 12.1821 1.33301 8.50016C1.33301 4.81826 4.31778 1.8335 7.99967 1.8335C11.6816 1.8335 14.6663 4.81826 14.6663 8.50016Z"
                    stroke="#52B44A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{" "}
              </div>
              <div>
                <p>Availability of Electricity</p>{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M4.99967 8.50016L6.99967 10.5002L10.9997 6.50016M14.6663 8.50016C14.6663 12.1821 11.6816 15.1668 7.99967 15.1668C4.31778 15.1668 1.33301 12.1821 1.33301 8.50016C1.33301 4.81826 4.31778 1.8335 7.99967 1.8335C11.6816 1.8335 14.6663 4.81826 14.6663 8.50016Z"
                    stroke="#52B44A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{" "}
              </div>
              <div>
                <p>Availability of Sewerage System</p>{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M4.99967 8.50016L6.99967 10.5002L10.9997 6.50016M14.6663 8.50016C14.6663 12.1821 11.6816 15.1668 7.99967 15.1668C4.31778 15.1668 1.33301 12.1821 1.33301 8.50016C1.33301 4.81826 4.31778 1.8335 7.99967 1.8335C11.6816 1.8335 14.6663 4.81826 14.6663 8.50016Z"
                    stroke="#52B44A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="radio-wraper" style={{ width: "100%" }}>
        <p className="info-text">
          {t("Do you have the required down payment of 60,000SAR?")}
        </p>
        <div>
          <ButtonWithRadio
            name={t("no")}
            value={1}
            required={required}
            setPilote={setRequired}
          />
          <ButtonWithRadio
            name={t("yes")}
            value={2}
            required={required}
            setPilote={setRequired}
          />
        </div>
      </div>
      <div className="btn-container">
        <BackButton url="/calendartype" />
        <button className="green-btn" onClick={() => navigate("/")}>
          {t("nextBtn")}
        </button>
      </div>
    </div>
  );
}

export default Confirmation;
