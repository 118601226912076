import React from 'react'

function ButtonWithRadio({name,value,pilote,setPilote}) {
  return (
    <div className={pilote===value?'checked-radio-button-wrapper':'radio-button-wrapper'} onClick={()=>setPilote(value)}>
          <input type='radio'
            checked={pilote===value}
          />
          <p style={{color:pilote===value&&"#52B44A",fontWeight:'600'}}>{name}</p>
    </div>
  )
}

export default ButtonWithRadio