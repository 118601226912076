import React, { useEffect, useState } from 'react'
import './Steps.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Progress from '../../components/Progress'
import Buttons from '../../components/Buttons'
import BackButton from '../../components/BackButton'
import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import ButtonWithRadio from '../../components/ButtonWithRadio'
import InputWithSelect from '../../components/InputWithSelect'
import DataPicker from '../../components/DatePicker'
import {getData} from './../../Utils/storage'
import { setDefaultSimah } from '../../Utils/storage'
function Step3() {
    const {t} = useTranslation()
    const [simah,isSimah] = useState(0)
    const navigate = useNavigate()
    useEffect(()=>{
      isSimah(getData().defaultSimah==false?1:getData().defaultSimah==true?2:0)
    },[])
    return (
        <div className='conatainer'>
    <div className='progress-container'>
    <Progress done={60}/>
    <p className='progress-value'>60%</p>
    </div>
      <div className='size-controled'></div>
      <h1 className='header-text'>{t('title4')}</h1>
      <div className='radio-wraper'><p className='info-text'>{t('simahText')}</p><div><ButtonWithRadio name={t('simahValuw2')} value={1} pilote={simah} setPilote={isSimah}/><ButtonWithRadio name={t('simahValue1')} value={2} pilote={simah} setPilote={isSimah}/></div></div>
      <div className='btn-container'>
        <BackButton url='/obligations'/> 
      <button className='green-btn' disabled={!simah} style={{opacity:!simah?'0.7':'1'}} onClick={()=>{
        localStorage.setItem('step3',simah)
        setDefaultSimah(simah==1?false:true)
        navigate('/homeownership')}}>{t('nextBtn')}</button>
      </div>

  </div>
    )
}

export default Step3
