import React, { useState } from 'react'
import Slider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';

const marks = [
  {
    value: 0.5,
  },
  {
    value: 1,
  },
  {
    value: 1.5,
  },
  {
    value: 2,
  },
  {
    value: 2.5,
  },
  {
    value: 3,
  },
  {
    value: 3.5,
  },
  {
    value: 4,
  },
  {
    value: 4.5,
  },
  {
    value: 5,
  },

];

function Sliders({value,setValue}) {
  const {t,i18n} = useTranslation()
  return (
    <div style={{width:'100%', transform: i18n.language==='ar'?'scaleX(-1)':''}} >
        <Slider 
        marks={marks}
        defaultValue={value} value={value} valueLabelDisplay="on"   step={null} dir="rtl"  min={0.5} max={5}  onChange={e=>i18n.language==='ar'?setValue(5.5-e.target.value):setValue(e.target.value)}/>
        <div style={{display:'flex', width:'100%', justifyContent:'space-between', transform: i18n.language==='ar'?'scaleX(-1)':''}}><span>{t('month6')}</span><span>{t('year5')}</span></div>
    </div>
  )
}

export default Sliders  