import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BackButton({ url, disabled }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    //     <div className='back-button' onClick={()=>navigate(url)}>
    // <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
    //   <path d="M30 36.5L18 24.5L30 12.5" stroke="#696969" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    // </svg>
    //     </div>
    <button
      style={{
        background: disabled ? "#D3D3D3" : "var(--Brand-Green, #FFF)",
        opacity: disabled ? "0.5" : "1",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      className="white-btn"
      disabled={disabled ? true : false}
      onClick={() => navigate(url)}
    >
      {t("Previous")}
    </button>
  );
}

export default BackButton;
