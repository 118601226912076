import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setDefaultSimah } from '../../Utils/storage'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

function GuideSecond() {
    const {t} = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [selectedBtn,setSelectedBtn] = useState(null)
    return (
        <div style={{justifyContent:"center"}}className='Step1'>
  
        <p className='pforStep1'>{t('title17')}</p>
         <div className='btn-container' style={{flexWrap:'wrap'}} >
            <button onClick={()=>setSelectedBtn(1)} className={selectedBtn===1?'checked':'dontChecked'}>{t('Apartment')}</button>
            <button onClick={()=>setSelectedBtn(2)} className={selectedBtn===2?'checked':'dontChecked'}>{t('Villa')}</button>
            <button onClick={()=>setSelectedBtn(3)} className={selectedBtn===3?'checked':'dontChecked'}>{t('Floor')}</button>
            <button onClick={()=>setSelectedBtn(4)} className={selectedBtn===4?'checked':'dontChecked'}>{t('Land')}</button>
            <button onClick={()=>{
                setSelectedBtn(false)}} className={selectedBtn==false?'checked':'dontChecked'}>{t('Other')}</button>
         </div>
        <button style={{marginTop:'120px'}} onClick={()=>{
            setDefaultSimah(selectedBtn)
            navigate(`/guidthird`)}} className='Btn' >{t('next')}</button>
   
    </div>
    )
}

export default GuideSecond