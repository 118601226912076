import React, { useState } from 'react'
import { useEffect } from 'react';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import InputOption from '../../components/Select'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Obligation() {
  const {t,i18n} = useTranslation()
  const { id } = useParams()

  const [person, setPerson] = useState(false)
  const [car, setCar] = useState(false)
  const [mortgage, setMortgage] = useState(false)
  const [creditCard, setCreditCard] = useState(false)
  const [obligations, setObligations] = useState([
    {
      type: 'person',
      value: ['', ''],
      isSelected:false
    },
    {
      type: 'car',
      value: ['', ''],
      isSelected:false
    },
    {
      type: 'mor',
      value: ['', ''],
      isSelected:false
    },
    {
      type: 'credit',
      value: ['', ''],
      isSelected:false
    }
  ])
  const [selectedOptions, setSelectedOptions] = useState([])
  const Values = {
    person:1,
    car:2,
    mor:3,
    credit:4
  }

  const navigate = useNavigate()

  const handelSubmit = () => {
    axios.put('leads/update-lead-obligations', {
          obligations:obligations.filter(ob=>ob.isSelected===true)?.map(i=>{ 
                   return {obligationType: Values[i.type],
                     amount: i.value[0],
                     monthsRemaining: i.value[1]}
        }),
          token:id
    }).then(() => navigate(`/simah/${id}`)).catch(e => console.error(e))
  }
  const onChange = (type, input1, input2) => {
    const newObligations = obligations.map((ob) => {
      if (ob.type === type) {
        ob.value[0] = input1 === null ? ob.value[0] : input1
        ob.value[1] = input2 === null ? ob.value[1] : input2
      }
      return ob
    })
    setObligations(newObligations)
  }

  return (
    <div style={{justifyContent:'flex-start'}} className='Step1'>

      <p className='pforStep1'>{t('title3')}</p>
      <div className='top-input-container'>
        <div className='list'>
          <div className='checkBoxContainer'>
            <Checkbox
              className='checkBox'
              icon={<Icon.FiCheck color="#0EB500" size={18} />}
              checked={person}
              onChange={(value, event) =>{
                obligations.map(ob=>{
                  if (ob.type === 'person') {
                          ob.isSelected = value
                  }
                  return ob
                })
                setPerson(value)}}
              size={22}
              borderColor="#F8F8F8;"
              style={{ cursor: "pointer", border: '1px solid rgba(0, 0, 0, 0.40)' }}

            />
            <span>{t('value1')}</span>
          </div>
          {window.innerWidth > 600 || person ? <div className='rightpart'>
            <div style={{ marginRight: '15px' }} className='input-form'>
              <div className='montly-title'><span>{t('installment')}</span></div>
              <input className='inputs' type='number' defaultValue={0} disabled={!person} onChange={(e) => onChange('person', e.target.value, null)} />
            </div>
            <div style={{ marginRight: '15px' }} className='input-form'>
              <div className='montly-title'><span>{t('complete')}</span></div>
              <input className='inputs' type='number' defaultValue={0} disabled={!person} onChange={(e) => onChange('person', null, e.target.value)}  />
            </div>
          </div> : null}
        </div>
        <hr className='line' />
        <div className='list'>
          <div className='checkBoxContainer'>
            <Checkbox
              className='checkBox'
              icon={<Icon.FiCheck color="#0EB500" size={18} />}
              checked={car}
              onChange={(value, event) => {
                obligations.map(ob=>{
                  if (ob.type === 'car') {
                          ob.isSelected = value
                  }
                  return ob
                })
                setCar(value)
              }}
              size={22}
              borderColor="#F8F8F8;"
              style={{ cursor: "pointer", border: '1px solid rgba(0, 0, 0, 0.40)' }}

            />
            <span>{t('value2')}</span>
          </div>
          {window.innerWidth > 600 || car ? <div className='rightpart'>
            <div style={{ marginRight: '15px' }} className='input-form'>
              {window.innerWidth < 600 ? <div className='montly-title'><span>Monthly installment</span></div> : null}
              <input className='inputs' type='number' defaultValue={0} disabled={!car} onChange={(e) => onChange('car', e.target.value, null)}/>
            </div>
            <div style={{ marginRight: '15px' }} className='input-form'>
              {window.innerWidth < 600 ? <div className='montly-title'><span>Months to complete</span></div> : null}
              <input className='inputs' type='number' defaultValue={0} disabled={!car} onChange={(e) => onChange('car', null, e.target.value)}/>
            </div>
          </div> : null}

        </div>
        <hr className='line' />
        <div className='list'>
          <div className='checkBoxContainer'>
            <Checkbox
              className='checkBox'
              icon={<Icon.FiCheck color="#0EB500" size={18} />}
              checked={mortgage}
              onChange={(value, event) => {
                obligations.map(ob=>{
                  if (ob.type === 'mor') {
                          ob.isSelected = value
                  }
                  return ob
                })
                setMortgage(value)}}
              size={22}
              borderColor="#F8F8F8;"
              style={{ cursor: "pointer", border: '1px solid rgba(0, 0, 0, 0.40)' }}

            />
            <span>{t('value3')}</span>
          </div>
          {window.innerWidth > 600 || mortgage ? <div className='rightpart'>
            <div style={{ marginRight: '15px', marginBottom: '10px' }} className='input-form'>
              {window.innerWidth < 600 ? <div className='montly-title'><span>Monthly installment</span></div> : null}
              <input className='inputs' type='number' defaultValue={0} disabled={!mortgage} onChange={(e) => onChange('mor', e.target.value,null)} />
            </div>
            <div style={{ marginRight: '15px' }} className='input-form'>
              {window.innerWidth < 600 ? <div className='montly-title'><span>Months to complete</span></div> : null}
              <input className='inputs' type='number' defaultValue={0} disabled={!mortgage}  onChange={(e) => onChange('mor',null,e.target.value)} />
            </div>
          </div> : null}

        </div>
        <hr className='line' />
        <div className='list'>
          <div className='checkBoxContainer'>
            <Checkbox
              className='checkBox'
              icon={<Icon.FiCheck color="#0EB500" size={18} />}
              checked={creditCard}
              onChange={(value, event) =>{
                obligations.map(ob=>{
                  if (ob.type === 'credit') {
                          ob.isSelected = value
                  }
                  return ob
                })
                setCreditCard(value)}}
              size={22}
              borderColor="#F8F8F8;"
              style={{ cursor: "pointer", border: '1px solid rgba(0, 0, 0, 0.40)' }}

            />
            <span>{t('value4')}</span>
          </div>
          {window.innerWidth > 600 || creditCard ? <div className='rightpart'>
            <div style={{ marginRight: '15px', marginBottom: '10px' }} className='input-form'>
              {window.innerWidth < 600 ? <div className='montly-title'><span>Monthly installment</span></div> : null}
              <input className='inputs' type='number' defaultValue={0} disabled={!creditCard} onChange={(e) => onChange('credit',e.target.value,null)} />
            </div>
            <div style={{ marginRight: '15px' }} className='input-form'>
              {window.innerWidth < 600 ? <div className='montly-title'><span>Months to complete</span></div> : null}
              <input className='inputs' type='number' defaultValue={0} disabled={!creditCard} onChange={(e) => onChange('credit',null,e.target.value)}/>
            </div>
          </div> : null}

        </div>
        
      </div>

      <button  className='Btn' onClick={() => navigate('/simah')} >{t('next')}</button>
    </div>
  )
}

export default Obligation