import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function DownPayment() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [selectedBtn,setSelectedBtn] = useState('No')
    return (
        <div className='Step1'>
        <p className='pforStep1'>{t('title14')}</p>
         <div className='btn-container'>
            <button onClick={()=>setSelectedBtn("No")} className={selectedBtn==="No"?'checked':'dontChecked'}>{t('no')}</button>
            <button onClick={()=>setSelectedBtn("Yes")} className={selectedBtn==="Yes"?'checked':'dontChecked'}>{t('yes')}</button>
         </div>
        <button style={{marginTop:'120px'}} onClick={()=>navigate('/location')} className='Btn' >{t('next')}</button>
    </div>
    )
}

export default DownPayment
