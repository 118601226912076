import React, { useEffect } from 'react'
import { useState } from 'react'
import Buttons from '../../components/Buttons'
import BackButton from '../../components/BackButton'
import Progress from '../../components/Progress'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setFirstMortgage } from '../../Utils/storage'
import { getData } from '../../Utils/storage'

function Step4() {
    const [value,setValue] = useState(0)
    const {t} = useTranslation()
    const navigate = useNavigate()
    const data = getData()
 
    useEffect(()=>{
      setValue(data.firstMortgage===false?2:data.firstMortgage===true?1:0)
    },[])
  return (
    <div className='conatainer'>
      <div className='progress-container'>
      <Progress done={70}/>
      <p className='progress-value'>70%</p>
      </div>
        <h1 className='header-text'>{t('title5')}</h1>
        <div className='green-text-wrapper'>
          <p>{t('infoHeader1')}</p>
          <span>{t('infoText1')}.</span>
        </div>
        <div className='info' style={{width:'100%'}}>
         <p className='info-text'>{t('question')}</p>
     <div className='selection-container-second'>
      <Buttons style={'100%'} name={t('value6')}  value={1} jobType={value}  setType={setValue} >
        {value==1?<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M16.6673 5L7.50065 14.1667L3.33398 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>:<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M15 5L5 15M5 5L15 15" stroke="#52B44A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>}
      </Buttons>
      <Buttons style={'100%'} name={t('value7')}  value={2} jobType={value}  setType={setValue} >
      {value==2?<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M16.6673 5L7.50065 14.1667L3.33398 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>:<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M15 5L5 15M5 5L15 15" stroke="#52B44A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>}
      </Buttons>

       </div>
        </div>
      
        <div className='btn-container'>
        <BackButton url='/obligations'/> 
      <button className='green-btn' disabled={!value} style={{opacity:!value?'0.7':'1'}} onClick={()=>{
        localStorage.setItem('step4',value)
        setFirstMortgage(value==1?true:false)
        navigate('/eligible')}}>{t('nextBtn')}</button>
      </div>

    </div>
  )
}

export default Step4