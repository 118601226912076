import React from 'react';
import { useRef } from 'react';
import { FormControl, Select, MenuItem, IconButton, InputAdornment } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';

function InputSelect({ name, placeHolder, changeValue, options, value, style }) {
    const selectRef = useRef(null);
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              borderColor: '#52B44A', // Your desired focus border color
            },
          },
        },
      },
    },
  });

  const handleIconClick = () => {
    if (selectRef.current) {
      selectRef.current.focus();
      selectRef.current.click();
    }
  };

  return (
    <div className="select-label">
      <p>{name}</p>
      <FormControl fullWidth>
        <Select
          ref={selectRef}
          labelId="input-select-label"
          id="my-select"
          theme={theme}
          value={value}
          onChange={(e) => changeValue(e.target.value)}
          label={name}
          input={<OutlinedInput />}
          inputProps={{ 'aria-label': 'Without label' }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleIconClick}>
                {/* <img src={icon} alt="Dropdown Icon" style={{ width: '24px', height: '24px' }} /> */}
              </IconButton>
            </InputAdornment>
          }
        >
          {placeHolder && <MenuItem value={''}>{placeHolder}</MenuItem>}
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default InputSelect;
