let data = {
    firstMortgage: null,
}
let limitResponce = {
    min:null,
    max:null
}
export const getData = () =>{
    return data
}
export const getResponce = () =>{
    return limitResponce
}
export const setMax = (max) => {
    limitResponce.max = max
}
export const setMin = (min) => {
    limitResponce.min = min
}
export const setDefaultSimah = (defaultSimah)=>{
    data.defaultSimah = defaultSimah
}
export const setFirstMortgage = (firstMortgage)=>{
    data.firstMortgage = firstMortgage
}
