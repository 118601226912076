import React from 'react'

function LimitesContainer({name,limit}) {
  return (
    <div className='limit-container'>
        <p>{name}</p>
        <h2>{limit}</h2>
    </div>
  )
}

export default LimitesContainer