import React, { useState } from 'react'
import Slider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function ProportyPrice() {
  const { t } = useTranslation()
  const [price, setPrice] = useState(200000)
  const navigate = useNavigate()

  return (
    <div className='Step1'>

      <p className='pforStep1'>{t('title12')}</p>

      <p className='pforStep1'>{t('subTitle12')}</p>
      <div className='container'>
        <div className='input-form'>
          <input className='inputs' value={`${price} ${t('moneyType')}`} type='text' onChange={(e) => setPrice(e.target.value)} />
        </div>
        <Slider
          onChange={(_, value) => setPrice(value)}
          value={price}
          min={200000}
          max={500000}
          valueLabelDisplay="auto"
          aria-label="pretto slider"
        />
        <div className='range-limit'>
          <span>{t('moneyOne')}</span>
          <span>{t('moneyTwo')}</span>
        </div>
      </div>
      <p className='pforStep1'>{t('info13')}</p>
      <button className='Btn' onClick={() => navigate('/payment')} >{t('next')}</button>
    </div>
  )
}

export default ProportyPrice