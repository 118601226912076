import React, { useState } from "react";

const NumberInput = ({placeholder,value,changeValue}) => {
//   const [value, setValue] = useState("");

  const handleInputChange = (event) => {
    let inputValue = event.target.value;

    // Convert Arabic numerals to English
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    const englishNumerals = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    arabicNumerals.forEach((arabic, index) => {
      inputValue = inputValue.replaceAll(arabic, englishNumerals[index]);
    });

    // Remove non-numeric characters except commas
    inputValue = inputValue.replace(/[^0-9,]/g, "");

    // Prevent negative numbers
    if (inputValue.startsWith("-")) {
      inputValue = inputValue.slice(1);
    }

    // Remove all existing commas
    inputValue = inputValue.replace(/,/g, "");

    // Auto-insert commas for formatting (from right to left)
    let formattedValue = "";
    for (let i = inputValue.length - 1, count = 0; i >= 0; i--, count++) {
      formattedValue = inputValue[i] + formattedValue;
      if (count === 2 && i > 0) {
        formattedValue = "," + formattedValue;
        count = -1;
      }
    }

    changeValue(formattedValue);
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleInputChange}
      placeholder={placeholder}
      inputMode="numeric"
    />
  );
};

export default NumberInput;